
import { PageTemplate } from './PageTemplate'
import { WFMatchingButtons } from '../components/Workflow/WfActionsComponent';
import { CustomerCandidateComponent } from '../components/Customer/CustomerCandidateComponent'
import { CustomerCandidatesComponent } from '../components/Customer/CustomerCandidatesComponent'

const ShowCustomerCandidates = () => {
    return (
        <PageTemplate
            type="customer" headerPage="quicklauncher" headerElement="candidates"
            leftPaneComponent={CustomerCandidatesComponent()} mainComponent={CustomerCandidateComponent()} buttonComponent={WFMatchingButtons()} >
        </PageTemplate>
    );
}
export { ShowCustomerCandidates }